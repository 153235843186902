import React from 'react';
import './ContactForm.scss';
import { useState } from 'react';
import { MdCall, MdEmail, MdRoom } from 'react-icons/md';

const ContactForm = () => {
  const [messageLength, setMessageLength] = useState(0);
  return (
    <section className="contact-form">
      <div className="container">
        <div className="contact-form__header">
          <div className="contact-form__question">
            Tem uma pergunta?
          </div>
          <div className="contact-form__title">
            Fale conosco
          </div>
          <div className="contact-form__description">
            Envie-nos uma mensagem e vamos te responder o quanto antes. Nós estamos aqui para te ajudar!
          </div>
        </div>
        <div className="contact-form__form">
          <form action="https://getform.io/f/4802c650-caa0-4bc8-ba5a-4e3f1a6fba30" method="POST">
            <div className="contact-form__wrap">
              <div className="contact-form__field">
                <label
                  className="contact-form__field__label"
                  htmlFor="name"
                >
                  Nome
                </label>
                <input type="text" name="name" id="name" maxLength="50" />
              </div>
              <div className="contact-form__field">
                <label
                  className="contact-form__field__label"
                  htmlFor="email"
                >
                  E-mail
                </label>
                <input type="text" name="email" id="email" maxLength="50" />
              </div>
              <div className="contact-form__field">
                <label
                  className="contact-form__field__label"
                  htmlFor="subject"
                >
                  Assunto
                </label>
                <input type="text" name="subject" id="subject" maxLength="50" />
              </div>
              <div className="contact-form__field">
                <label
                  className="contact-form__field__label"
                  htmlFor="message"
                >
                  Mensagem ({messageLength}/200)
                </label>
                <textarea
                  style={{ resize: `none` }}
                  rows="4"
                  type="text"
                  maxLength="200"
                  name="message"
                  onChange={(e) => setMessageLength(e.target.value.length)}
                  id="message" />
              </div>
              <div className="contact-form__action">
                <button type="submit" className="action-button full-width">Entrar em contato</button>
              </div>
            </div>
          </form>
          <div className="contact-form__contact-information__wrapper">
            <div className="contact-form__contact-information">
              <div className="contact-form__contact-information__title">
                Informações para contato
            </div>
              <div className="contact-form__contact-information__row">
                <div className="contact-form__contact-information__row__icon">
                  <MdRoom />
                </div>
                <div className="contact-form__contact-information__row__text">
                  <span>Rua Francisco Foga, 890</span>
                  <span>Distrito Industrial - Vinhedo, São Paulo</span>
                </div>
              </div>
              <div className="contact-form__contact-information__row">
                <div className="contact-form__contact-information__row__icon">
                  <MdCall />
                </div>
                <div className="contact-form__contact-information__row__text">
                  <span>(19) 3856-9191</span>
                  <span>0800 776-3071</span>
                </div>
              </div>
              <div className="contact-form__contact-information__row">
                <div className="contact-form__contact-information__row__icon">
                  <MdEmail />
                </div>
                <div className="contact-form__contact-information__row__text">
                  <span>marbel@marbelplast.com.br</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section >
  )
}

export default ContactForm;