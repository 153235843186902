import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout";
import ContactForm from "../components/contato/contactForm/ContactForm";

const ContactPage = () => {
  return (
    <Layout>
      <SEO title="Contato" />
      <ContactForm />
    </Layout>
  )
}

export default ContactPage;
